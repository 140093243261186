.user-value-card {
  padding: 3px;
  max-height: 220px;
  overflow: auto;
}
.user-value-panel {
  margin-bottom: 3px !important;
}
.user-value-card.overflow-visible {
  overflow: visible !important;
}
