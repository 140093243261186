html ::-webkit-scrollbar-thumb:hover {
  background: var(--cui-color-gray-400);
}
.cui .content {
  overflow: initial;
}

.new-user-wrap {
  border-right: var(--cui-border);
}
.pl-4 {
  padding-left: 15px;
}

.pr-4 {
  padding-right: 15px;
}

@media (min-width: 768px) {
  .user-register-form {
    width: 75%;
    margin: 0 auto;
  }
}
.cui .content {
  min-height: calc(101vh - 77px);
}
.company-form-panel {
  min-height: 425px;
}
.company-list-wrap,
.company-type-list-wrap {
  min-height: 380px;
  max-height: 380px;
  overflow-x: clip;
  overflow-y: auto;
  /* border-bottom: var(--cui-border); */
}
.organization-form-wrap {
  min-height: 168px;
  max-height: 168px;
  overflow: auto;
  /* border-bottom: var(--cui-border); */
  position: relative;
}
.place-holder-box {
  padding: 15px;
  width: 100%;
  /* border: var(--cui-border); */
  text-align: center;
  /* background-color: var(--cui-border-color); */
  border: 1px dotted var(--cui-color-gray-600);
}
.place-holder-box .place-holder-title {
  color: var(--cui-color-gray-600);
}
.center-holder-wrap {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

@media only screen and (min-width: 768px) {
  .cui .col--bordered-right {
    border-right: var(--cui-border);
  }
}

.fullscreen-loader {
  align-items: center;
  background: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s linear;
  width: 100%;
  z-index: 9999;
  opacity: 1;
  transform: opacity 1s linear;
}

.accordion-title {
  display: inline-flex;
  justify-content: space-between;
  color: var(--cui-inactive-color);
  padding: var(--cui-spacing-qtr) 0;
  cursor: pointer;
}

.accordion-content {
  margin-top: var(--cui-spacing-half);
  margin-left: 19px;
}
.Toastify__toast {
  border-radius: var(--cui-border-radius);
  box-shadow: var(--cui-shadow-outset-lg);
}
.space-bar {
  margin-left: 10px;
  margin-right: 10px;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.steps .step_info {
  max-width: 100%;
  margin-left: 2px;
  margin-right: 2px;
  overflow: scroll;
  background-color: var(--cui-background-inactive);
  border-radius: var(--cui-panel-border-radius);
  padding: var(--cui-panel-default-padding);
  color: var(--cui-font-color);
  overflow: auto;
}

.cui .steps.request-steps .step.visited .step__icon {
  background-color: var(--cui-theme-primary);
  border: var(--cui-step-line-thickness-visited) solid var(--cui-theme-primary);
  color: var(--cui-theme-primary-color);
  font-weight: 400;
}

.cui .steps.request-steps .step.active .step__icon {
  background-color: var(--cui-theme-success);
  border: var(--cui-step-line-thickness-visited) solid var(--cui-theme-success);
  color: var(--cui-theme-success-color);
  font-weight: 400;
}
.cui .steps.request-steps .comment-wrap {
  white-space: pre-wrap;
  max-height: 60px;
  overflow: auto;
}
.panel.panel--gray {
  background-color: var(--cui-color-gray-200);
}
.cui .steps.steps--primary .step .step__icon.large-step-icon {
  width: 75px;
  max-width: 75px;
  height: 75px;
}

.cui .sort-header .default-sort {
  opacity: 0;
}

.cui .sort-header:hover .default-sort {
  opacity: 1;
}
.cui .sort-header {
  cursor: pointer;
}
.cui .header-icon-item {
  font-size: 22px;
}
.cui .request-steps {
  overflow: auto;
}

.cui .request-steps .table.table--compressed td,
.cui .request-steps .table.table--compressed th,
.cui .request-steps .table.table--compressed tr {
  padding: 4px 8px;
}

.cui .company-steps .step .step__icon {
  margin-bottom: 0px;
  margin-left: 5px;
}

.cui .steps.company-steps {
  margin-bottom: 0px;
}

.cui .step .step__icon .step-text {
  white-space: nowrap;
}

.cui .header ~ .secondary-header {
  padding-top: var(--cui-header-height);
}

/* ag grid start */
.cui .ag-theme-alpine {
  --ag-font-family: var(--cui-font-stack);
  --ag-font-size: var(--cui-font-size);
  --ag-foreground-color: var(--cui-font-color);
  --ag-data-color: var(--ag-foreground-color);
  --ag-border-color: var(--cui-border-color);
  --ag-alpine-active-color: var(--cui-theme-primary);
  --ag-grid-size: 6px;
  /* --ag-list-item-height: 20px; */
  --ag-cell-horizontal-padding: 8px;
}

.cui .ag-header {
  background-color: #fff;
}
.cui .ag-body {
  background-color: white;
}
.cui .ag-theme-alpine .ag-row,
.cui .ag-theme-alpine-dark .ag-row {
  font-size: var(--cui-font-size);
}
.cui .ag-table-title {
  border: var(--cui-border);
}
.cui .ag-theme-alpine.min-height-38-table .ag-center-cols-container,
.cui .ag-theme-alpine.min-height-38-table .ag-center-cols-clipper {
  min-height: 38px;
}
/* ag grid end */

.cui .search-title-wrap {
  border: var(--cui-border);
}

.cui .header {
  height: 94px;
}
.cui .header ~ .content {
  padding-top: 94px;
}
.cui .header-panels {
  padding-top: 8px;
  padding-bottom: 8px;
}

/* CSS Dropdown menu */
@media (max-width: 720px) {
  .cui .header {
    height: auto;
  }
  #scregheader + .content {
    padding-top: 100px;
  }
  #scregheader .header-panel.header-panel--right {
    position: relative;
    top: inherit;
    flex: 1;
  }
  .cui .navbar {
    display: none;
  }

  #navbarMenu.navbar {
    position: absolute;
    top: var(--cui-header-height);
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    border-bottom: var(--cui-border);
    padding: var(--cui-spacing-half) 0;
    background-color: var(--cui-background-color);
  }
}
.navbar {
  display: -webkit-box;
  background-color: var(--cui-background-active);
}
.header-menu {
  display: -webkit-box;
  padding: 0px 0px;
}

.header-menu a {
  text-align: center;
  display: block;
  white-space: nowrap;
  margin: 0;
  padding: 10px 10px;
}

.header-menu a:link,
.header-menu a:visited,
.header-menu a:active,
.cui .header-menu a {
  text-decoration: none;
  width: 100%;
  color: var(--cui-inactive-color);
}

.header-menu a.top_parent, .header-menu a.top_parent:hover  /* attaches down-arrow to all top-parents */ {
  /*background-image: url(navdown_white.gif);*/
  background-position: right center;
  background-repeat: no-repeat;
  padding: 10px 6px;
}

div.header-menu li:hover a:after,
div.header-menu li li:hover a:after,
div.header-menu li li li:hover a:after,
div.header-menu li li li li:hover a:after {
  transition: transform var(--cui-animate-speed)
    var(--cui-animate-timing-function);
  transform: rotate(180deg);
}

.header-menu a.top_parent:after,
.header-menu a.top_parent:after {
  transform: rotate(0);
  /* transition: transform var(--cui-animate-speed) var(--cui-animate-timing-function); */
  content: '';
  width: 0;
  height: 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  opacity: 1;
  border-radius: 2px;
  margin-bottom: 2px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--cui-font-color);
  align-self: center;
  margin-left: var(--cui-spacing-qtr);
  color: inherit;
  text-decoration: none;
}

.header-menu a.parent, .header-menu a.parent:hover 	/* attaches side-arrow to all parents */ {
  /* 	background-image: url(nav_white.gif); */
  background-position: right center;
  background-repeat: no-repeat;
}

.header-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
}

.header-menu li {
  position: relative;
  min-height: 1px;
  vertical-align: bottom;
  margin-right: 10px;
  width: 100%;
}

.cui .header-menu a:focus,
.cui .header-menu a:hover {
  text-decoration: none !important;
  color: var(--cui-active-color);
  background-color: var(--cui-background-hover);
}

.header-menu ul ul {
  position: absolute;
  z-index: 500;
  top: auto;
  display: none;
  margin: 0px 0 0 0px;
  list-style: none;
  background-color: #fff;
  border: var(--cui-border);
  border-radius: var(--cui-border-radius);
  color: var(--cui-font-color);
  pointer-events: auto;
  min-width: var(--cui-dropdown-menu-min-width);
  max-height: var(--cui-dropdown-max-height);
  text-align: left;
  box-shadow: var(--cui-shadow-outset);
  min-width: 320px;
  padding: 8px 0px !important;
  background-color: #fff;
}

.header-menu ul ul ul {
  top: 0;
  left: 100%;
}

div.header-menu li:hover {
  cursor: pointer;
  z-index: 100;
}

div.header-menu li:hover ul ul,
div.header-menu li li:hover ul ul,
div.header-menu li li li:hover ul ul,
div.header-menu li li li li:hover ul ul {
  display: none;
}

div.header-menu li:hover ul,
div.header-menu li li:hover ul,
div.header-menu li li li:hover ul,
div.header-menu li li li li:hover ul {
  display: block;
}

/* End CSS Drop Down Menu */

.cui .dashboard-menu-item {
  height: 90px;
  margin-bottom: 0px !important;
  background-color: #f2f2f2;
  width: 100%;
}

.cui .menu-card .menu-card-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.cui .panel.dashboard-menu-item a {
  color: var(--cui-font-color);
}
.menu-card {
  margin-bottom: 10px;
}
.menu-card a {
  width: 100%;
}

.menu-card .card-title {
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cui .menu-card a:hover {
  text-decoration: none;
}

.cui .card {
  display: block;
  position: relative;
  margin: 0;
  border: 0;
  border-radius: var(--cui-panel-border-radius);
  height: auto;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--cui-background-color);
  border: var(--cui-border);
  overflow: inherit;
}

.cui .card-body {
  flex: 1 1 auto;
  padding: var(--cui-spacing-half);
  color: var(--cui-font-color);
}

.cui .card-header {
  padding: var(--cui-spacing-half);
  border-bottom: 1px solid var(--cui-border-color);
}

.cui .card-header h5 {
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 16px;
  color: var(--cui-theme-primary) !important;
}

.cui .table.table--custom td,
.cui .table.table--custom tr,
.cui .table.table--custom th {
  padding: 6px 8px;
}

.custom-tooltip {
  border: var(--cui-border);
  border-radius: var(--cui-panel-border-radius);
  overflow: hidden;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.cui .record-info {
  margin: 2px 0;
  padding: 5px;
  background-color: #f8f8f8;
}
.cui .record-info .record-label {
  /* color: var(--cui-color-gray-600) !important; */
  font-weight: 700 !important;
}

.cui .record-info .record-value {
  font-weight: 400 !important;
  word-break: break-all;
}

.cui .record-info div {
  min-height: 21px;
}

.required:after {
  content: ' *';
  color: var(--cui-theme-danger);
}

.cui .modal.modal--fluid .modal__content {
  overflow: scroll;
}

/* Mobile menu start */

.cui .header a.menu-open-btn {
  display: none;
  font-size: 18px;
  padding: 0px 10px;
  margin-right: 15px;
  color: var(--cui-font-color);
}

@media (max-width: 720px) {
  .cui .header a.menu-open-btn {
    display: block;
  }
}
.sm-screen-navbar {
  visibility: hidden;
  transform: translateX(-100%);
  border-radius: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1200;
  width: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  background-color: var(--cui-background-hover);
  box-shadow: var(--cui-shadow-outset);
}
.cui .sm-screen-navbar .menu__close {
  font-size: var(--cui-modal-icon-size);
  position: absolute;
  right: var(--cui-spacing);
  top: var(--cui-spacing);
  color: var(--cui-inactive-color);
  z-index: 9999;
}

.sm-screen-navbar {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.sm-screen-navbar.show {
  visibility: visible;
  transform: translateX(0);
}

.navbar-nav {
  list-style: none;
  background: var(--cui-dropdown-background-color);
  color: var(--cui-font-color);
  pointer-events: auto;
  top: calc(100% + var(--cui-spacing-qtr));
  min-width: var(--cui-dropdown-menu-min-width);
  max-height: var(--cui-dropdown-max-height);
  transition: opacity var(--cui-animate-speed);
  text-align: left;
  user-select: none;
  z-index: var(--cui-dropdown-zindex);
}

.nav-item {
  line-height: inherit;
  display: block;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cui .nav-item a {
  height: var(--cui-dropdown-menu-item-height);
  line-height: inherit;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  padding: 8px 5px;
  color: var(--cui-active-color);

  width: auto;
}

.cui .nav-item a:hover {
  text-decoration: none;
}

/* Mobile menu end */

.edit-form-value-holder {
  min-height: 33px;
  background-color: #f2f2f2;
  padding: 8px;
}

.form-value-holder {
  min-height: 33px;
  padding: 8px;
}
.cui .react-tooltip {
  z-index: 1;
}

.filter-bar-row {
  display: flex;
  flex-wrap: wrap;
}
.filter-bar-item {
  width: 100%;
}
.filter-bar-item .filter-item input {
  width: 140px !important;
}
.filter-bar-item .filter-item .filter-item-title {
  width: 100px;
  margin-bottom: 0px !important;
}

.alert-close {
  position: absolute;
  top: -7px;
  right: 7px;
  font-size: 30px;
  cursor: pointer;
}

.cui .table-card-header {
  flex-direction: row;
  flex-wrap: wrap;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-flex-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.table-search-input {
  min-width: 280px;
}
.table-card-header h5 {
  margin-bottom: 0px;
}

.cui .rs-picker .rs-btn-default {
  border-radius: var(--cui-border-radius);
}
.cui .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: var(--cui-theme-primary);
}
.cui .rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  background-color: var(--cui-theme-primary-lighter);
}
.cui .rs-btn-primary {
  background-color: var(--cui-theme-primary);
}
.cui .rs-btn-primary:hover {
  background-color: var(--cui-theme-primary-lighter);
}

.cui .checkbox .checkbox__input:after {
  left: 6px !important;
  top: 2px !important;
}
.cui .datepicker {
  z-index: 1;
}

.actions-button-cell {
  overflow: visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}
.action-dropdown-menu-item {
  line-height: 30px;
}
.cui .label-click {
  cursor: pointer;
}
.cui .card-header .display-5 {
  margin-bottom: 0px;
  font-size: 2rem;
  font-weight: 300;
  color: inherit !important;
}

.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.range-datepicker {
  width: 250px;
}
