.cui .dropdown .btn:after,
.cui .dropdown > a:after {
  content: none;
}

.btn.btn--link {
  border: none;
  padding: 1px;
}

.dropdown.dropdown--right > .dropdown-body .dropdown-content {
  left: auto;
  right: 0;
}
.excel-export-btn .icon-excel {
  line-height: 20px;
  font-size: 12px;
}
